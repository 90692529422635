<template>
  <section class="dashboard">
    <div class="page-header">
      <h3 class="page-title">
        <!-- <span class="page-title-icon text-white mr-2 believe-btn"> -->
        <i class="mdi mdi-home"></i>
        <!-- </span>  -->
        Dashboard
      </h3>
      <nav aria-label="breadcrumb">
        <ul class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            <!-- <span></span>Overview <i class="mdi mdi-alert-circle-outline icon-sm text-primary align-middle"></i> -->
          </li>
        </ul>
      </nav>
    </div>
    <div class="row">
      <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class="mdi mdi-poll-box text-success icon-lg"></i>
                </div>
                <div class="float-right">
                  <p class="mb-0 text-right">Daily Sales</p>
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">$ {{ (allData?.revenue_today).toFixed(2) }}</h3>
                  </div>
                </div>
              </div>
              <p class="text-muted mt-3 mb-0">
                <i class="mdi mdi-currency-usd mr-1" aria-hidden="true"></i> Total sales {{ (allData?.revenue_total).toFixed(2) }}</p>
            </div>
          </div>
        </div> -->

        <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class="mdi mdi-account-plus text-warning icon-lg"></i>
                </div>
                <div class="float-right">

                  <div class="form-group">
                  <select class="custom-select all-selects" v-model="selectedOptionSignup" @change="onSelectChangeSignup">
                    <option value="daily">Daily Sign up</option>
                    <option value="yesterday">Previous day Sign up</option>
                    <option value="weakly">Weekly Sign up</option>
                    <option value="monthly">Monthly Sign up</option>
                    <option value="yearly">Yearly Sign up</option>
                  </select>
                  </div>
                  
                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">{{ signup_count }}</h3>
                  </div>
                </div>
              </div>
              <p class="text-muted mt-3 mb-0">
                <i class="mdi mdi-bookmark-outline mr-1" aria-hidden="true"></i> Total sign up {{  allData?.users_total  }} </p>
            </div>
          </div>
        </div> -->


        <!-- new  -->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="">
                <div class="">
                  <h5> <i class="mdi mdi-account-plus text-info icon-md mr-2"></i> Sign up</h5>                  
                </div>
                <div class="">
                  <p class="text-muted mt-4 ml-2 mb-0">
                  <i class="mdi mdi-calendar mr-1" aria-hidden="true"></i>Total sign up {{  allData?.users_total  }} 
                </p>
                </div>
              </div>
              <div class="row mt-5">
              <div class="col-md-6">
                <div class="form-group">
                  <select class="custom-select all-selects" v-model="selectedOptionSignup" @change="onSelectChangeSignup">
                    <option value="daily" selected>Daily</option>
                    <option value="yesterday">Previous day</option>
                    <option value="weakly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0">
                    <span class="badge badge-outline-info badge-pill"> {{ signup_count }}</span>
                  </h3>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>


        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="">
                <div class="">
                  <h5> <i class="mdi mdi-credit-card text-info icon-md mr-2"></i> Monthly Subscribers</h5>                  
                </div>
                <div class="">
                  <p class="text-muted mt-4 ml-2 mb-0">
                  <i class="mdi mdi-calendar mr-1" aria-hidden="true"></i>Total subscribes {{ allData?.subscriptions_today_total }}
                </p>
                </div>
              </div>
              <div class="row mt-5">
              <div class="col-md-6">
                <div class="form-group">
                  <select class="custom-select all-selects" v-model="selectedOptionSubscriber" @change="onSelectChangeSubscriber">
                    <option value="daily" selected>Daily</option>
                    <option value="yesterday">Previous day</option>
                    <option value="weakly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0"><span class="badge badge-outline-info badge-pill"> {{ subscriber_count }}</span></h3>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class="mdi mdi-credit-card text-danger icon-lg"></i>
                </div>
                <div class="float-right">
             
                  <div class="form-group">
                  <select class="custom-select all-selects" v-model="selectedOptionSubscriberYearly" @change="onSelectChangeSubscriberYearly">
                    <option value="daily" selected>Daily Subscribers (Yearly)</option>
                    <option value="yesterday">Previous day Subscribers (Yearly)</option>
                    <option value="weakly">Weekly Subscribers (Yearly)</option>
                    <option value="monthly">Monthly Subscribers (Yearly)</option>
                    <option value="yearly">Yearly Subscribers (Yearly)</option>
                  </select>
                  </div>

                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">{{ subscriber_count_yearly }}</h3>
                  </div>
                </div>
              </div>
              <p class="text-muted mt-3 mb-0">
                <i class="mdi mdi-calendar mr-1" aria-hidden="true"></i>Total subscribes {{ allData?.subscriptions_today_total_yearly }} </p>
            </div>
          </div>
        </div> -->


        <!-- new  -->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="">
                <div class="">
                  <h5> <i class="mdi mdi-credit-card text-info icon-md mr-2"></i> Yearly Subscribers</h5>                  
                </div>
                <div class="">
                  <p class="text-muted mt-4 ml-2 mb-0">
                  <i class="mdi mdi-calendar mr-1" aria-hidden="true"></i>Total subscribes {{ allData?.subscriptions_today_total_yearly }} 
                </p>
                </div>
              </div>
              <div class="row mt-5">
              <div class="col-md-6">
                <div class="form-group">
                  <select class="custom-select all-selects"  v-model="selectedOptionSubscriberYearly" @change="onSelectChangeSubscriberYearly">
                    <option value="daily" selected>Daily</option>
                    <option value="yesterday">Previous day</option>
                    <option value="weakly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0"><span class="badge badge-outline-info badge-pill"> {{ subscriber_count_yearly }}</span></h3>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class="mdi mdi-package-variant-closed text-info icon-lg"></i>
                </div>
                <div class="float-right">
                  
                  <div class="form-group">
                  <select class="custom-select all-selects" v-model="selectedOptionCancel" @change="onSelectChangeCancel">
                    <option value="daily" selected>Daily Cancellations</option>
                    <option value="yesterday">Previous day Cancellations</option>
                    <option value="weakly">Weekly Cancellations</option>
                    <option value="monthly">Monthly Cancellations</option>
                    <option value="yearly">Yearly Cancellations</option>
                  </select>
                  </div>

                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">{{ cancel_count }}</h3>
                  </div>
                </div>
              </div>
              <p class="text-muted mt-3 mb-0">
                <i class="mdi mdi-close-box-outline mr-1" aria-hidden="true"></i> Total cancellations {{ allData?.cancelations_today_total }}</p>
            </div>
          </div>
        </div> -->

        <!-- new  -->
        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="">
                <div class="">
                  <h5> <i class="mdi mdi-package-variant-closed text-info icon-md mr-2"></i> Cancellations</h5>                  
                </div>
                <div class="">
                  <p class="text-muted mt-4 ml-2 mb-0">
                  <i class="mdi mdi-calendar mr-1" aria-hidden="true"></i> Total cancellations {{ allData?.cancelations_today_total }} 
                </p>
                </div>
              </div>
              <div class="row mt-5">
              <div class="col-md-6">
                <div class="form-group">
                  <select class="custom-select all-selects"  v-model="selectedOptionCancel" @change="onSelectChangeCancel">
                    <option value="daily" selected>Daily</option>
                    <option value="yesterday">Previous day</option>
                    <option value="weakly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0"><span class="badge badge-outline-info badge-pill"> {{ cancel_count }}</span></h3>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>


        <!-- <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="clearfix">
                <div class="float-left">
                  <i class="mdi mdi-poll-box text-success icon-lg"></i>
                </div>
                <div class="float-right">
                
                  <div class="form-group">
                  <select class="custom-select all-selects"  v-model="selectedOptionRefund" @change="onSelectChangeRefund">
                    <option value="daily" selected>Daily Refunds</option>
                    <option value="yesterday">Previous day Refunds</option>
                    <option value="weakly">Weekly Refunds</option>
                    <option value="monthly">Monthly Refunds</option>
                    <option value="yearly">Yearly Refunds</option>
                  </select>
                  </div>

                  <div class="fluid-container">
                    <h3 class="font-weight-medium text-right mb-0">$ {{ Math.abs(refund_count).toFixed(2) }}</h3>
                  </div>
                </div>
              </div>
              <p class="text-muted mt-3 mb-0">
                <i class="mdi mdi-currency-usd mr-1" aria-hidden="true"></i> Total refunds {{ Math.abs((allData?.refund_total)).toFixed(2) }}</p>
            </div>
          </div>
        </div> -->


         <!-- new  -->
         <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="">
                <div class="">
                  <h5> <i class="mdi  mdi-poll-box text-info icon-md mr-2"></i> Refunds </h5>                  
                </div>
                <div class="">
                  <p class="text-muted mt-4 ml-2 mb-0">
                  <i class="mdi mdi-calendar mr-1" aria-hidden="true"></i> Total refunds {{ Math.abs((allData?.refund_total)).toFixed(2) }}
                </p>
                </div>
              </div>
              <div class="row mt-5">
              <div class="col-md-6">
                <div class="form-group">
                  <select class="custom-select all-selects" v-model="selectedOptionRefund" @change="onSelectChangeRefund">
                    <option value="daily" selected>Daily</option>
                    <option value="yesterday">Previous day</option>
                    <option value="weakly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0"><span class="badge badge-outline-info badge-pill">$ {{ Math.abs(refund_count).toFixed(2) }}</span></h3>
                  
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>


        <div class="col-xl-4 col-lg-6 col-md-6 col-sm-6 grid-margin stretch-card">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="">
                <div class="">
                  <h5> <i class="mdi mdi-calendar-clock text-info icon-md mr-2"></i> Reminders </h5>                  
                </div>
                <div class="">
                  <p class="text-muted mt-4 ml-2 mb-0">
                  <i class="mdi mdi-calendar mr-1" aria-hidden="true"></i> Total Reminders {{ allData?.totalReminders }}
                </p>
                </div>
              </div>
              <div class="row mt-5">
              <div class="col-md-6">
                <!-- <div class="form-group">
                  <select class="custom-select all-selects" v-model="selectedOptionRefund" @change="onSelectChangeRefund">
                    <option value="daily" selected>Daily</option>
                    <option value="yesterday">Previous day</option>
                    <option value="weakly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                  </select>
                </div> -->
              </div>
              <div class="col-md-6">
                <div class="fluid-container">
                  <h3 class="font-weight-medium text-right mb-0"><span class="badge badge-outline-info badge-pill">{{ allData?.totalReminders }}</span></h3>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>


        
      <!-- <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-danger card-img-holder text-white">
          <div class="card-body">
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Daily Sales <i class="mdi mdi-chart-line mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-5">$ {{ allData?.revenue_today }}</h2>
            <h6 class="card-text">Increased by 60%</h6>
          </div>
        </div>
      </div>
      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-info card-img-holder text-white">
          <div class="card-body">
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Daily Sign up 
              <i
                class="mdi mdi-account-plus mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-5">{{  allData?.users_today_total  }}</h2>
            <h6 class="card-text">Total Sign up {{  allData?.users_total  }}</h6>
          </div>
        </div>
      </div>
      <div class="col-md-4 stretch-card grid-margin">
        <div class="card bg-gradient-success card-img-holder text-white">
          <div class="card-body">
            <img src="../../assets/images/dashboard/circle.svg" class="card-img-absolute" alt="circle-image" />
            <h4 class="font-weight-normal mb-3">Daily Subscribes <i class="mdi mdi-credit-card mdi-24px float-right"></i>
            </h4>
            <h2 class="mb-5">{{ allData?.subscriptions_today_total_today  }}</h2>
            <h6 class="card-text">Total Subscribes {{ allData?.subscriptions_today_total }}</h6>
          </div>
        </div>
      </div> -->
    </div>
    <div class="row">

      <div class="col-md-8 grid-margin stretch-card">
      <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Today's Subscribers</h4>
            <h1 v-if="allData.subscribers_today == null || allData.subscribers_today.length == 0">
              No new subscriber found!</h1>
            <div class="table-responsive" v-else>
              <table class="table">
                <thead>
                  <tr>
                    <!-- <th> # </th> -->
                    <th> Image </th>
                    <th> Name </th>
                    <th> Email </th>
                    <th> Package </th>
                    <th> Action </th>
                    <!-- <th> Platform </th> -->
                    <!-- <th> totat pay </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user,index) in allData.subscribers_today">
                    <!-- <td> {{ index+1 }} </td> -->
                    <td><img 
                      :src="(user?.profile_image != '/images/avatar.jpg' && user?.customer?.profile_image != null && user?.customer?.profile_image != '') 
                      ? user?.customer?.profile_image :  baseUrl + '/images/avatar.jpg'"
                      class="mr-2" alt="Profile Image"></td>
                    <td> {{ user?.customer?.name }} </td>
                    <td> {{ user?.customer?.email }} </td>
                    <td> {{ user?.plan_id == '1' ? 'Monthly' : 'Yearly' }} </td>
                    <td>
                      <button class="btn btn-outline-warning" @click="showShowModal(user?.customer?.id)" v-b-tooltip.html.bottom
                      title="View details">
                      <i class="fa fa-eye"></i>
                    </button>
                    </td>
                    <!-- <td> {{ user.planName.split('_')[0] }} </td> -->
                    <!-- <td> {{ user.total_amount }} </td> -->
                    <!-- <td>
                      <div class="progress">
                        <div class="progress-bar bg-gradient-success" role="progressbar" style="width: 25%"
                          aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            
          </div>
          <div class="card-footer text-right">
              <router-link to="/subscribed-users/list" class="btn btn-primary btn-sm">See All</router-link>
            </div>
        </div>
        </div>

     
        <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title text-center">subscribers vs non-subscribers</h1>
            <div v-if="showChart" class="mt-5">
              <trafficSourceChart :height='200' :labels=SubNonStatsArray :values=[this.subscriptions_percent2.toFixed(2),this.nonsubscriptions_percent.toFixed(2)]></trafficSourceChart>
            </div>
              <div class="col-md-12">
                  <!-- <h4 class="card-title font-weight-medium mb-0 d-none d-md-block">Subscriptions</h4> -->
                  <div class="wrapper mt-4">
                    <div class="d-flex justify-content-between mb-2">
                      <div class="d-flex align-items-center">
                        <p class="mb-0 font-weight-medium">{{ allData?.subscriptions_today_total_all }}</p>
                        <small class="text-muted ml-2">Subscribers</small>
                      </div>
                      <p class="mb-0 font-weight-medium">{{ (subscriptions_percent2).toFixed(2) }}%</p>
                    </div>
                    <div class="progress">
                      <div class="progress-bar bg-success" role="progressbar" :style="'width:'+subscriptions_percent2+'%'" aria-valuenow="88" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div class="wrapper mt-4">
                    <div class="d-flex justify-content-between mb-2">
                      <div class="d-flex align-items-center">
                        <p class="mb-0 font-weight-medium">{{ allData?.non_subscriptions_today_total_all }}</p>
                        <small class="text-muted ml-2">Non Subscribers</small>
                      </div>
                      <p class="mb-0 font-weight-medium">{{ (nonsubscriptions_percent).toFixed(2) }}%</p>
                    </div>
                    <div class="progress">
                      <div class="progress-bar bg-info" role="progressbar" :style="'width:'+nonsubscriptions_percent+'%'" aria-valuenow="34" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>

      
    </div>

    <div class="row">


      <div class="col-md-8 stretch-card grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Today's Sign up</h4>
            <h1 v-if="allData.new_users == null || allData.new_users.length == 0">No new users found!</h1>
            <div class="table-responsive" v-else>
              <table class="table">

                <thead>
                  <tr>
                    <!-- <th> # </th> -->
                    <th> Image </th>
                    <th> Name </th>
                    <th> Email </th>
                    <th> Gender </th>
                    <th> View </th>
                    <!-- <th> Platform </th> -->
                    <!-- <th> totat pay </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user,index) in allData.new_users">
                    <td><img 
                      :src="(user?.profile_image != '/images/avatar.jpg' && user?.profile_image != null && user?.profile_image != '') ? user?.profile_image :  baseUrl + '/images/avatar.jpg'"
                       class="mr-2" alt="image"></td>
                    <td> {{ user.name }} </td>
                    <td> {{ user.email }} </td>
                    <td> {{ user.gender != 'null' && user.gender != null ? user.gender : 'unknown'}} </td>
                    <td>
                      <button class="btn btn-outline-warning" @click="showShowModal(user?.id)" v-b-tooltip.html.bottom
                      title="View details">
                      <i class="fa fa-eye"></i>
                    </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
          </div>
          <div class="card-footer text-right">
              <router-link to="/users/list" class="btn btn-primary btn-sm">See All</router-link>
            </div>
        </div>
      </div>

      

      <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title text-center">Gender Distribution Ratio</h1>
            <div v-if="showChart" class="mt-5">
              <trafficSourceChart :labels=genderStatsArray :height='200' :values=[this.male_percent.toFixed(2),this.female_percent.toFixed(2),this.other_percent.toFixed(2)]></trafficSourceChart>
            </div>
              <div class="col-md-12">
                  <!-- <h4 class="card-title font-weight-medium mb-0 d-none d-md-block">Subscriptions</h4> -->
                  <div class="wrapper mt-4">
                    <div class="d-flex justify-content-between mb-2">
                      <div class="d-flex align-items-center">
                        <p class="mb-0 font-weight-medium">{{ allData?.male }}</p>
                        <small class="text-muted ml-2">Male</small>
                      </div>
                      <p class="mb-0 font-weight-medium">{{ (male_percent).toFixed(2) }}%</p>
                    </div>
                    <div class="progress">
                      <div class="progress-bar bg-success" role="progressbar" :style="'width:'+male_percent+'%'" aria-valuenow="88" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div class="wrapper mt-4">
                    <div class="d-flex justify-content-between mb-2">
                      <div class="d-flex align-items-center">
                        <p class="mb-0 font-weight-medium">{{ allData?.female }}</p>
                        <small class="text-muted ml-2">Female</small>
                      </div>
                      <p class="mb-0 font-weight-medium">{{ (female_percent).toFixed(2) }}%</p>
                    </div>
                    <div class="progress">
                      <div class="progress-bar bg-info" role="progressbar" :style="'width:'+female_percent+'%'" aria-valuenow="34" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div class="wrapper mt-4">
                    <div class="d-flex justify-content-between mb-2">
                      <div class="d-flex align-items-center">
                        <p class="mb-0 font-weight-medium">{{ allData?.other }}</p>
                        <small class="text-muted ml-2">Other</small>
                      </div>
                      <p class="mb-0 font-weight-medium">{{ (other_percent).toFixed(2) }}%</p>
                    </div>
                    <div class="progress">
                      <div class="progress-bar bg-warning" role="progressbar" :style="'width:'+other_percent+'%'" aria-valuenow="34" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>

      <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title text-center">Subscrptions & Cancellations Ratio</h1>
            <div v-if="showChart" class="mt-5">
              <trafficSourceChart :height='200' :values=[this.subscriptions_percent.toFixed(2),this.cancelations_percent.toFixed(2)]></trafficSourceChart>
            </div>
              <div class="col-md-12">
                  <!-- <h4 class="card-title font-weight-medium mb-0 d-none d-md-block">Subscriptions</h4> -->
                  <div class="wrapper mt-4">
                    <div class="d-flex justify-content-between mb-2">
                      <div class="d-flex align-items-center">
                        <p class="mb-0 font-weight-medium">{{ allData?.subscriptions_today_total_all }}</p>
                        <small class="text-muted ml-2">Subscriptions</small>
                      </div>
                      <p class="mb-0 font-weight-medium">{{ (subscriptions_percent).toFixed(2) }}%</p>
                    </div>
                    <div class="progress">
                      <div class="progress-bar bg-success" role="progressbar" :style="'width:'+subscriptions_percent+'%'" aria-valuenow="88" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div class="wrapper mt-4">
                    <div class="d-flex justify-content-between mb-2">
                      <div class="d-flex align-items-center">
                        <p class="mb-0 font-weight-medium">{{ allData?.cancelations_today_total }}</p>
                        <small class="text-muted ml-2">Cancellations</small>
                      </div>
                      <p class="mb-0 font-weight-medium">{{ (cancelations_percent).toFixed(2) }}%</p>
                    </div>
                    <div class="progress">
                      <div class="progress-bar bg-info" role="progressbar" :style="'width:'+cancelations_percent+'%'" aria-valuenow="34" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>


      <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title text-center">Package Type Distribution Ratio</h1>
            <div v-if="showChart" class="mt-5">
              <trafficSourceChart :height='200' :labels=packageStatsArray :values=[this.yearly_percent.toFixed(2),this.monthly_percent.toFixed(2)]></trafficSourceChart>
            </div>
              <div class="col-md-12">
                  <!-- <h4 class="card-title font-weight-medium mb-0 d-none d-md-block">Subscriptions</h4> -->
                  <div class="wrapper mt-4">
                    <div class="d-flex justify-content-between mb-2">
                      <div class="d-flex align-items-center">
                        <p class="mb-0 font-weight-medium">{{ allData?.subscriptions_today_total_yearly }}</p>
                        <small class="text-muted ml-2">Yearly</small>
                      </div>
                      <p class="mb-0 font-weight-medium">{{ (yearly_percent).toFixed(2) }}%</p>
                    </div>
                    <div class="progress">
                      <div class="progress-bar bg-success" role="progressbar" :style="'width:'+yearly_percent+'%'" aria-valuenow="88" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div class="wrapper mt-4">
                    <div class="d-flex justify-content-between mb-2">
                      <div class="d-flex align-items-center">
                        <p class="mb-0 font-weight-medium">{{ allData?.subscriptions_today_total }}</p>
                        <small class="text-muted ml-2">Monthly</small>
                      </div>
                      <p class="mb-0 font-weight-medium">{{ (monthly_percent).toFixed(2) }}%</p>
                    </div>
                    <div class="progress">
                      <div class="progress-bar bg-info" role="progressbar" :style="'width:'+monthly_percent+'%'" aria-valuenow="34" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
      



      <div class="col-md-4 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h1 class="card-title text-center">Signup Platforms Distribution Ratio</h1>
            <div v-if="showChart" class="mt-5">
              <trafficSourceChart :labels=platformStatsArray :height='200' :values=[this.android_percent.toFixed(2),this.ios_percent.toFixed(2),this.web_percent.toFixed(2)]></trafficSourceChart>
            </div>
              <div class="col-md-12">
                  <!-- <h4 class="card-title font-weight-medium mb-0 d-none d-md-block">Subscriptions</h4> -->
                  <div class="wrapper mt-4">
                    <div class="d-flex justify-content-between mb-2">
                      <div class="d-flex align-items-center">
                        <p class="mb-0 font-weight-medium">{{ allData?.android }}</p>
                        <small class="text-muted ml-2">Android</small>
                      </div>
                      <p class="mb-0 font-weight-medium">{{ (android_percent).toFixed(2) }}%</p>
                    </div>
                    <div class="progress">
                      <div class="progress-bar bg-success" role="progressbar" :style="'width:'+android_percent+'%'" aria-valuenow="88" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div class="wrapper mt-4">
                    <div class="d-flex justify-content-between mb-2">
                      <div class="d-flex align-items-center">
                        <p class="mb-0 font-weight-medium">{{ allData?.ios }}</p>
                        <small class="text-muted ml-2">IOS</small>
                      </div>
                      <p class="mb-0 font-weight-medium">{{ (ios_percent).toFixed(2) }}%</p>
                    </div>
                    <div class="progress">
                      <div class="progress-bar bg-info" role="progressbar" :style="'width:'+ios_percent+'%'" aria-valuenow="34" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                  <div class="wrapper mt-4">
                    <div class="d-flex justify-content-between mb-2">
                      <div class="d-flex align-items-center">
                        <p class="mb-0 font-weight-medium">{{ allData?.web }}</p>
                        <small class="text-muted ml-2">Web</small>
                      </div>
                      <p class="mb-0 font-weight-medium">{{ (web_percent).toFixed(2) }}%</p>
                    </div>
                    <div class="progress">
                      <div class="progress-bar bg-warning" role="progressbar" :style="'width:'+web_percent+'%'" aria-valuenow="34" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>



      

     
    </div>

    <div class="row" v-if="allData != null || allData.length > 0">
      <div class="col-6 grid-margin">
        <div class="card">
          <div class="card-body">

            
            <h4 class="card-title">Most Listened Tracks</h4>
            <!-- <b-tabs align="right">
            <b-tab v-if="allData.top_listen != null && allData.top_listen.length > 0" title="Top" active> -->

              <select class="custom-select all-selects" v-model="selectedOptionPopular" @change="onSelectChangePopular">
                    <option value="daily" selected>Daily</option>
                    <option value="yesterday">Previous day</option>
                    <option value="weakly">Weekly</option>
                    <option value="monthly">Monthly</option>
                    <option value="yearly">Yearly</option>
                    <option value="lifetime">Lifetime</option>
                  </select>

              <div class="table-responsive"  v-if="popular_tracks != null && popular_tracks.length > 0">
                <table class="table">
                <thead>
                  <tr>
                    <th> Image </th>
                    <th> Title </th>
                    <!-- <th> Listened </th> -->
                    <th> Track </th>
                    <th> Track Since </th>
                    <th> Type </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(toptracks, toptrackindex) in popular_tracks">
                    <td>
                      <!-- ../../assets/images/faces/face1.jpg -->
                      <img :src="toptracks?.image" class="mr-2" alt="image">
                    </td>
                    <td> {{ toptracks?.name }} </td>
                    <!-- <td> {{ toptracks?.views }} </td> -->
                    <td>
                      <a href="javascript:;" @click="play_aud(toptracks?.path)"
                        class="btn btn-outline-success btn-rounded listbox w3-bar-item w3-button believe-btn">
                        <i class="mdi mdi-play"></i>
                      </a>
                    </td>
                    <td> {{ getFormattedTime(toptracks?.created_at) }} </td>
                    <td> {{ toptracks?.type }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h3 v-else>No Track Found!</h3>
          
          </div>
        </div>
      </div>



      <div class="col-6 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Most Favorite Tracks</h4>

            <select class="custom-select all-selects" v-model="selectedOptionFavorite" @change="onSelectChangeFavorite">
                    <option value="daily_top" selected>Daily (top 5)</option>
                    <option value="daily_last" >Daily (last 5)</option>
                    <option value="yesterday_top">Previous day (top 5)</option>
                    <option value="yesterday_last">Previous day (last 5)</option>
                    <option value="weakly_top">Weekly (top 5)</option>
                    <option value="weakly_last">Weekly (last 5)</option>
                    <option value="monthly_top">Monthly (top 5)</option>
                    <option value="monthly_last">Monthly (last 5)</option>
                    <option value="yearly_top">Yearly (top 5)</option>
                    <option value="yearly_last">Yearly (last 5)</option>
                    <option value="lifetime_top">Lifetime (top 5)</option>
                    <option value="lifetime_last">Lifetime (last 5)</option>
                  </select>

            <!-- <b-tabs align="right">
            <b-tab v-if="allData.fav_audios_top != null && allData.fav_audios_top.length > 0"  title="Top" active> -->
            <div v-if="favorite_tracks != null && favorite_tracks.length > 0"  title="Top" active>
              <div class="table-responsive">
                <table class="table">
                <thead>
                  <tr>
                    <th> Image </th>
                    <th> Title </th>
                    <th> Favorites </th>
                    <th> Track </th>
                    <th> Track Since </th>
                    <th> Type </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(toptracks, toptrackindex) in favorite_tracks">
                    <td>
                      <!-- ../../assets/images/faces/face1.jpg -->
                      <img :src="toptracks?.image" class="mr-2" alt="image">
                    </td>
                    <td> {{ toptracks?.name }} </td>
                    <td> {{ toptracks?.fav_count }} </td>
                    <td>
                      <a href="javascript:;" @click="play_aud(toptracks?.path)"
                        class="btn btn-outline-success btn-rounded listbox w3-bar-item w3-button believe-btn">
                        <i class="mdi mdi-play"></i>
                      </a>
                    </td>
                    <td> {{ getFormattedTime(toptracks?.created_at) }} </td>
                    <td> {{ toptracks?.type }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
            <h3 v-else>No Track Found!</h3>
          </div>
        </div>
      </div>






      <div class="col-6 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Recently Listened</h4>
            <div v-if="allData.recent_played != null && allData.recent_played.length > 0" title="Top" active>
              <div class="table-responsive">
                <table class="table">
                <thead>
                  <tr>
                    <th> Image </th>
                    <th> Title </th>
                    <th> Listened </th>
                    <th> Track </th>
                    <th> Track Since </th>
                    <th> Type </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(toptracks, toptrackindex) in allData?.recent_played">
                    <td>
                      <!-- ../../assets/images/faces/face1.jpg -->
                      <img :src="toptracks?.image" class="mr-2" alt="image">
                    </td>
                    <td> {{ toptracks?.name }} </td>
                    <td> {{ toptracks?.views }} </td>
                    <td>
                      <a href="javascript:;" @click="play_aud(toptracks?.path)"
                        class="btn btn-outline-success btn-rounded listbox w3-bar-item w3-button believe-btn">
                        <i class="mdi mdi-play"></i>
                      </a>
                    </td>
                    <td> {{ getFormattedTime(toptracks?.created_at) }} </td>
                    <td> {{ toptracks?.type }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
        </div>
      </div>

      <div class="col-6 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Most Added to Playlist</h4>
            <div class="form-group">
                  <select class="custom-select all-selects" v-model="selectedOptionPlaylist" @change="onSelectChangePlaylist">
                    <option value="daily_top" selected>Daily (top 5)</option>
                    <option value="daily_last" >Daily (last 5)</option>
                    <option value="yesterday_top">Previous day (top 5)</option>
                    <option value="yesterday_last">Previous day (last 5)</option>
                    <option value="weakly_top">Weekly (top 5)</option>
                    <option value="weakly_last">Weekly (last 5)</option>
                    <option value="monthly_top">Monthly (top 5)</option>
                    <option value="monthly_last">Monthly (last 5)</option>
                    <option value="yearly_top">Yearly (top 5)</option>
                    <option value="yearly_last">Yearly (last 5)</option>
                    <option value="lifetime_top">Lifetime (top 5)</option>
                    <option value="lifetime_last">Lifetime (last 5)</option>
                  </select>
                </div>

          
            <div v-if="playlist_tracks != null && playlist_tracks.length > 0"  title="Top" active>
              <div class="table-responsive">
                <table class="table">
                <thead>
                  <tr>
                    <th> Image </th>
                    <th> Title </th>
                    <!-- <th> Playlist </th> -->
                    <th> Track </th>
                    <th> Track Since </th>
                    <th> Type </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(toptracks, toptrackindex) in playlist_tracks">
                    <td>
                      <!-- ../../assets/images/faces/face1.jpg -->
                      <img :src="toptracks?.image" class="mr-2" alt="image">
                    </td>
                    <td> {{ toptracks?.name }} </td>
                    <!-- <td> {{ toptracks?.playlistName }} </td> -->
                    <td>
                      <a href="javascript:;" @click="play_aud(toptracks?.path)"
                        class="btn btn-outline-success btn-rounded listbox w3-bar-item w3-button believe-btn">
                        <i class="mdi mdi-play"></i>
                      </a>
                    </td>
                    <td> {{ getFormattedTime(toptracks?.created_at) }} </td>
                    <td> {{ toptracks?.type }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            </div>
            <h3 v-else>No Track Found!</h3>
          </div>
        </div>
      </div>


      <div class="col-6 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Top 5 users with the most minutes played</h4>
            <div v-if="allData.largestMinutesCountUsers != null && allData.largestMinutesCountUsers.length > 0" title="Top" active>
              <div class="table-responsive">
                <table class="table">
                <tbody>
                  <tr v-for="(user,index) in allData.largestMinutesCountUsers">
                    <td><img 
                      :src="(user?.profile_image != '/images/avatar.jpg' && user?.profile_image != null && user?.profile_image != '') ? user?.profile_image :  baseUrl + '/images/avatar.jpg'"
                       class="mr-2" alt="image"></td>
                    <td> {{ user.name }} </td>
                    <td> {{ user.email }} </td>
                    <td> {{ user.gender != 'null' && user.gender != null ? user.gender : ''}} </td>
                    <td>
                      <button class="btn btn-outline-warning" @click="showShowModal(user?.id)" v-b-tooltip.html.bottom
                      title="View details">
                      <i class="fa fa-eye"></i>
                    </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
        </div>
      </div>


      <div class="col-6 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Top 5 users with the most sessions completed</h4>
            <div v-if="allData.largestSessionsCountUsers != null && allData.largestSessionsCountUsers.length > 0" title="Top" active>
              <div class="table-responsive">
                <table class="table">
                <tbody>
                  <tr v-for="(user,index) in allData.largestSessionsCountUsers">
                    <td><img 
                      :src="(user?.profile_image != '/images/avatar.jpg' && user?.profile_image != null && user?.profile_image != '') ? user?.profile_image :  baseUrl + '/images/avatar.jpg'"
                       class="mr-2" alt="image"></td>
                    <td> {{ user.name }} </td>
                    <td> {{ user.email }} </td>
                    <td> {{ user.gender != 'null' && user.gender != null ? user.gender : ''}} </td>
                    <td>
                      <button class="btn btn-outline-warning" @click="showShowModal(user?.id)" v-b-tooltip.html.bottom
                      title="View details">
                      <i class="fa fa-eye"></i>
                    </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          </div>
        </div>
      </div>




      <!-- <div class="col-6 grid-margin" v-if="allData != null && allData.top_listen != null">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Most Listened Top Tracks</h4>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th> Image </th>
                    <th> Title </th>
                    <th> Views </th>
                    <th> Track </th>
                    <th> Track Since </th>
                    <th> Type </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(toptracks, toptrackindex) in allData?.top_listen">
                    <td>
                      <img :src="toptracks?.image" class="mr-2" alt="image">
                    </td>
                    <td> {{ toptracks?.name }} </td>
                    <td> {{ toptracks?.views }} </td>
                    <td>
                      <a href="javascript:;" @click="play_aud(toptracks?.path)"
                        class="btn btn-outline-success btn-rounded listbox w3-bar-item w3-button believe-btn">
                        <i class="mdi mdi-play"></i>
                      </a>
                    </td>
                    <td> {{ toptracks?.created_at | moment('ll') }} </td>
                    <td> {{ toptracks?.type }} </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> -->


      


      <!-- <div class="col-6 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Most Listened Last Tracks 2</h4>
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th> Assignee </th>
                    <th> Subject </th>
                    <th> Status </th>
                    <th> Last Update </th>
                    <th> Tracking ID </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img src="../../assets/images/faces/face1.jpg" class="mr-2" alt="image"> David Grey </td>
                    <td> Fund is not recieved </td>
                    <td>
                      <label class="badge badge-gradient-success">DONE</label>
                    </td>
                    <td> Dec 5, 2017 </td>
                    <td> WD-12345 </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="../../assets/images/faces/face2.jpg" class="mr-2" alt="image"> Stella Johnson </td>
                    <td> High loading time </td>
                    <td>
                      <label class="badge badge-gradient-warning">PROGRESS</label>
                    </td>
                    <td> Dec 12, 2017 </td>
                    <td> WD-12346 </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="../../assets/images/faces/face3.jpg" class="mr-2" alt="image"> Marina Michel </td>
                    <td> Website down for one week </td>
                    <td>
                      <label class="badge badge-gradient-info">ON HOLD</label>
                    </td>
                    <td> Dec 16, 2017 </td>
                    <td> WD-12347 </td>
                  </tr>
                  <tr>
                    <td>
                      <img src="../../assets/images/faces/face4.jpg" class="mr-2" alt="image"> John Doe </td>
                    <td> Loosing control on server </td>
                    <td>
                      <label class="badge badge-gradient-danger">REJECTED</label>
                    </td>
                    <td> Dec 3, 2017 </td>
                    <td> WD-12348 </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- <div class="row">
      <div class="col-lg-5 grid-margin stretch-card">
        <div class="card">
          <date-picker v-model="time1" valueType="format" inline></date-picker>
        </div>
      </div>
      <div class="col-lg-7 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Recent Updates</h4>
            <div class="d-flex">
              <div class="d-flex align-items-center mr-4 text-muted font-weight-light">
                <i class="mdi mdi-account-outline icon-sm mr-2"></i>
                <span>jack Menqu</span>
              </div>
              <div class="d-flex align-items-center text-muted font-weight-light">
                <i class="mdi mdi-clock icon-sm mr-2"></i>
                <span>October 3rd, 2018</span>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-6 pr-1">
                <img src="../../assets/images/dashboard/img_1.jpg" class="mb-2 mw-100 w-100 rounded" alt="image">
                <img src="../../assets/images/dashboard/img_4.jpg" class="mw-100 w-100 rounded" alt="image">
              </div>
              <div class="col-6 pl-1">
                <img src="../../assets/images/dashboard/img_2.jpg" class="mb-2 mw-100 w-100 rounded" alt="image">
                <img src="../../assets/images/dashboard/img_3.jpg" class="mw-100 w-100 rounded" alt="image">
              </div>
            </div>
            <div class="d-flex mt-5 align-items-top">
              <img src="../../assets/images/faces/face3.jpg" class="img-sm rounded-circle mr-3" alt="image">
              <div class="mb-0 flex-grow">
                <h5 class="mr-2 mb-2">School Website - Authentication Module.</h5>
                <p class="mb-0 font-weight-light">It is a long established fact that a reader will be distracted by the
                  readable content of a page.</p>
              </div>
              <div class="ml-auto">
                <i class="mdi mdi-heart-outline text-muted"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    

    <div class="row">

      <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
          <div class="card-header">
            <h5 class="pt-3">Comprehensive list of all goals along with the number of users</h5>
          </div>
          <div class="card-body" style="height: 200px !important; overflow-y: auto;">
            <table class="table"  v-if="allData?.goalsWithUserCounts.length > 0">
              <tr>
                <th>Goal</th>
                <th>Users</th>
              </tr>
              <tbody >
                <tr v-for="goal in allData?.goalsWithUserCounts">
                <td>{{ goal.name }}</td>
                <td>{{ goal.user_count }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>


      <div class="col-md-6 grid-margin stretch-card">
  <div class="card">
    <div class="card-header">
      <h5 class="pt-3">Comprehensive list of all feelings along with the number of users</h5>
    </div>
    <div class="card-body" style="height: 200px !important; overflow-y: auto;">
      <table class="table"  v-if="allData?.feelingsWithUserCounts.length > 0">
        <tr>
          <th>Feeling</th>
          <th>Users</th>
        </tr>
        <tbody >
          <tr v-for="goal in allData?.feelingsWithUserCounts">
          <td>{{ goal.name }}</td>
          <td>{{ goal.user_count }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

      <div class="col-md-3 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h5 class=" mb-4">Top 5 most common goals set by users</h5>
            <ul v-if="allData?.topFiveGoals.length > 0">
              <li v-for="goal in allData?.topFiveGoals">
                {{ goal.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-3 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h5 class=" mb-4">Most recent 5 goals set by users</h5>
            <ul v-if="allData?.latestFiveGoals.length > 0">
              <li v-for="goal in allData?.latestFiveGoals">
                {{ goal.name }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      

<div class="col-md-3 grid-margin stretch-card">
  <div class="card">
    <div class="card-body">
      <h5 class=" mb-4">Top 5 most common feelings set by users</h5>
      <ul v-if="allData?.topFiveFeelings.length > 0">
        <li v-for="goal in allData?.topFiveFeelings">
          {{ goal.name }}
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="col-md-3 grid-margin stretch-card">
  <div class="card">
    <div class="card-body">
      <h5 class=" mb-4">Most recent 5 feelings set by users</h5>
      <ul v-if="allData?.latestFiveFeelings.length > 0">
        <li v-for="goal in allData?.latestFiveFeelings">
          {{ goal.name }}
        </li>
      </ul>
    </div>
  </div>
</div>

</div>


<div class="row">

<div class="col-md-3 grid-margin stretch-card">
  <div class="card">
    <div class="card-header">
      <h5 class="pt-3">Most common times for reminders</h5>
    </div>
    <div class="card-body">

      <ul v-if="allData?.mostCommonReminderTime.length > 0">
        <li v-for="reminder in allData?.mostCommonReminderTime">
          {{ getFormattedTime(reminder,'h:mm a') }}
        </li>
      </ul>
    </div>
  </div>
</div>


<div class="col-md-9 grid-margin stretch-card">
<div class="card">
<div class="card-header">
<h5 class="pt-3">Most recent 5 reminders set by users</h5>
</div>
<div class="card-body" style="height: 200px !important; overflow-y: auto;">
<table class="table"  v-if="allData?.mostRecentReminders.length > 0">
  <tr>
    <th>Reminder</th>
    <th>Time</th>
  </tr>
  <tbody >
    <tr v-for="reminder in allData?.mostRecentReminders">
    <td>{{ reminder.title }}</td>
    <td>{{ getFormattedTime(reminder.time_too,'h:mm a')}}</td>
  </tr>
  </tbody>
</table>
</div>
</div>
</div>
</div>


  </section>
</template>

<script>
import visitAndSalesStatitics from '../../components/charts/widgets/visitAndSalesStatitics'
import trafficSourceChart from '../../components/charts/widgets/trafficSourceChart'
import todoList from '../../components/apps/todoList'
import DatePicker from 'vue2-datepicker';
import api from "../../config/api.js";

export default {
  name: 'dashboard',
  components: {
    DatePicker,
    trafficSourceChart,
    visitAndSalesStatitics,
    todoList,
  },
  data() {
    return {
      popularTracks : [],
      playlist_tracks : [],
      favorite_tracks : [],
      genderStatsArray : ['Male','Female','Other'],
      platformStatsArray: ['Android','IOS','Web'],
      packageStatsArray : ['Yearly','Monthly'],
      SubNonStatsArray: ['Subscriber','Non subscriber'],
      selectedOptionSignup: 'daily',
      selectedOptionPlaylist: 'lifetime_top',
      selectedOptionPopular: 'lifetime',
      selectedOptionFavorite: 'lifetime_top',
      selectedOptionSubscriber: 'daily',
      selectedOptionSubscriberYearly: 'daily',
      selectedOptionCancel: 'daily',
      selectedOptionRefund: 'daily',
      signup_count: 0,
      subscriber_count: 0,
      subscriber_count_yearly: 0,
      cancel_count: 0,
      refund_count: 0,
      baseUrl : "",
      time1: null,
      allData: [],
      cancelations_percent : 0,
      subscriptions_percent : 0,
      subscriptions_percent2 : 0,
      nonsubscriptions_percent : 0,
      male_percent : 0,
      female_percent : 0,
      other_percent : 0,
      android_percent : 0,
      ios_percent : 0,
      web_percent : 0,
      yearly_percent : 0,
      monthly_percent : 0,
      showChart : false,
    };
  },
  created(){
    this.baseUrl = "https://api.believehypnosis.app";
    // window.location.origin
    this.getAllData();
  },
  mounted() {
    // console.log('moment :::: ' +  this.$moment());
    // console.log(this.$store.getters['getname']);
    this.setStore();
  },
  methods: {

    onSelectChangeSignup(){
      switch (this.selectedOptionSignup) {
        case 'daily':
          this.signup_count = this.allData?.users_today_total;
          break;
        case 'yesterday':
          this.signup_count = this.allData?.users_yesterday_total;
          break;
        case 'weakly':
          this.signup_count = this.allData?.users_weakly_total;
          break;
        case 'monthly':
          this.signup_count = this.allData?.users_monthly_total;
          break;
        case 'yearly':
          this.signup_count = this.allData?.users_yearly_total;
          break;
        default:
          break;
      }
    },


    onSelectChangePopular(){
      switch (this.selectedOptionPopular) {
        case 'daily':
          this.popular_tracks = this.allData?.mostPopularToday;
          break;
        case 'yesterday':
          this.popular_tracks = this.allData?.mostPopularYesterday;
          break;
        case 'weakly':
          this.popular_tracks = this.allData?.mostPopularWeakly;
          break;
        case 'monthly':
          this.popular_tracks = this.allData?.mostPopularMonthly;
          break;
        case 'yearly':
          this.popular_tracks = this.allData?.mostPopularYearly;
          break;
        case 'lifetime':
          this.popular_tracks = this.allData?.mostPopularLifetime;
          break;
        default:
          break;
      }
    },

    onSelectChangePlaylist() {
      switch (this.selectedOptionPlaylist) {
        case 'daily_top':
          this.playlist_tracks = this.allData?.mostAddedInPlaylistTodayTop;
          break;
        case 'daily_last':
          this.playlist_tracks = this.allData?.mostAddedInPlaylistTodayLast;
          break;
        case 'yesterday_top':
          this.playlist_tracks = this.allData?.mostAddedInPlaylistYesterdayTop;
          break;
        case 'yesterday_last':
          this.playlist_tracks = this.allData?.mostAddedInPlaylistYesterdayLast;
          break;
        case 'weakly_top':
          this.playlist_tracks = this.allData?.mostAddedInPlaylistWeaklyTop;
          break;
        case 'weakly_last':
          this.playlist_tracks = this.allData?.mostAddedInPlaylistWeaklyLast;
          break;
        case 'monthly_top':
          this.playlist_tracks = this.allData?.mostAddedInPlaylistMonthlyTop;
          break;
        case 'monthly_last':
          this.playlist_tracks = this.allData?.mostAddedInPlaylistMonthlyLast;
          break;
        case 'yearly_top':
          this.playlist_tracks = this.allData?.mostAddedInPlaylistYearlyTop;
          break;
        case 'yearly_last':
          this.playlist_tracks = this.allData?.mostAddedInPlaylistYearlyLast;
          break;
        case 'lifetime_top':
          this.playlist_tracks = this.allData?.mostAddedInPlaylistLifeTimeTop;
          break;
        case 'lifetime_last':
          this.playlist_tracks = this.allData?.mostAddedInPlaylistLifeTimeLast;
          break;
        default:
          break;
      }
    },


    onSelectChangeFavorite() {
      switch (this.selectedOptionFavorite) {
        case 'daily_top':
          this.favorite_tracks = this.allData?.mostAddedToFavoritesToday?.top;
          break;
        case 'daily_last':
          this.favorite_tracks = this.allData?.mostAddedToFavoritesToday?.last;
          break;
        case 'yesterday_top':
          this.favorite_tracks = this.allData?.mostAddedToFavoritesYesterday?.top;
          break;
        case 'yesterday_last':
          this.favorite_tracks = this.allData?.mostAddedToFavoritesYesterday?.last;
          break;
        case 'weakly_top':
          this.favorite_tracks = this.allData?.mostAddedToFavoritesWeakly?.top;
          break;
        case 'weakly_last':
          this.favorite_tracks = this.allData?.mostAddedToFavoritesWeakly?.last;
          break;
        case 'monthly_top':
          this.favorite_tracks = this.allData?.mostAddedToFavoritesMonthly?.top;
          break;
        case 'monthly_last':
          this.favorite_tracks = this.allData?.mostAddedToFavoritesMonthly?.last;
          break;
        case 'yearly_top':
          this.favorite_tracks = this.allData?.mostAddedToFavoritesYearly?.top;
          break;
        case 'yearly_last':
          this.favorite_tracks = this.allData?.mostAddedToFavoritesYearly?.last;
          break;
        case 'lifetime_top':
          this.favorite_tracks = this.allData?.mostAddedToFavoritesLifetime?.top;
          break;
        case 'lifetime_last':
          this.favorite_tracks = this.allData?.mostAddedToFavoritesLifetime?.last;
          break;
        default:
          break;
      }
    },

    onSelectChangeSubscriber(){
      switch (this.selectedOptionSubscriber) {
        case 'daily':
          this.subscriber_count = this.allData?.subscriptions_today_total_today;
          break;
        case 'yesterday':
          this.subscriber_count = this.allData?.subscriptions_yesterday_real;
          break;
        case 'weakly':
          this.subscriber_count = this.allData?.subscriptions_weakly_real;
          break;
        case 'monthly':
          this.subscriber_count = this.allData?.subscriptions_monthly_real;
          break;
        case 'yearly':
          this.subscriber_count = this.allData?.subscriptions_yearly_real;
          break;
        default:
          break;
      }
    },

    onSelectChangeSubscriberYearly(){
      switch (this.selectedOptionSubscriberYearly) {
        case 'daily':
          this.subscriber_count_yearly = this.allData?.subscriptions_today_real_yearly;
          break;
        case 'yesterday':
          this.subscriber_count_yearly = this.allData?.subscriptions_yesterday_real_yearly;
          break;
        case 'weakly':
          this.subscriber_count_yearly = this.allData?.subscriptions_weakly_real_yearly;
          break;
        case 'monthly':
          this.subscriber_count_yearly = this.allData?.subscriptions_monthly_real_yearly;
          break;
        case 'yearly':
          this.subscriber_count_yearly = this.allData?.subscriptions_yearly_real_yearly;
          break;
        default:
          break;
      }
    },

    onSelectChangeCancel(){
      switch (this.selectedOptionCancel) {
        case 'daily':
          this.cancel_count = this.allData?.cancelations_today_total_today;
          break;
        case 'yesterday':
          this.cancel_count = this.allData?.cancelations_yesterday;
          break;
        case 'weakly':
          this.cancel_count = this.allData?.cancelations_weakly;
          break;
        case 'monthly':
          this.cancel_count = this.allData?.cancelations_monthly;
          break;
        case 'yearly':
          this.cancel_count = this.allData?.cancelations_yearly;
          break;
        default:
          break;
      }
    },

    onSelectChangeRefund(){
      switch (this.selectedOptionRefund) {
        case 'daily':
          this.refund_count = this.allData?.refund_today;
          break;
        case 'yesterday':
          this.refund_count = this.allData?.refund_yesterday;
          break;
        case 'weakly':
          this.refund_count = this.allData?.refund_weakly;
          break;
        case 'monthly':
          this.refund_count = this.allData?.refund_monthly;
          break;
        case 'yearly':
          this.refund_count = this.allData?.refund_yearly;
          break;
        default:
          break;
      }
    },

    async showShowModal(id) {
      await this.$store.dispatch("setDataInEditForm", {
        "pageNumber": 0, "type": "dashboard", id
      });

      var obj = {
        "pageNumber": 0, "type": "dashboard", id
      };

      localStorage.setItem('data_for_edit', JSON.stringify(obj));

      const url2 = `/user/${id}/show`;
      this.$router.push({ path: url2 });
    },

    play_aud(path) {
      this.$swal({
        // icon: 'audio',
        title: "Audio",
        html: " <audio controls><source src=\"" + path + "\" type=\"audio/mpeg\" /></audio>",
        showCloseButton: true,
        showConfirmButton: false,
      });
    },

    async getAllData() {
      let result = await api.get("admin/dashboard-data");
      if (result.status == 200) {
        this.allData = result?.data;
        this.showChart = true;
        const sub_data_total = this.allData.cancelations_today_total + this.allData.subscriptions_today_total_all;
        this.cancelations_percent = this.allData.cancelations_today_total / sub_data_total * 100;
        this.subscriptions_percent = this.allData.subscriptions_today_total_all / sub_data_total * 100;

        
        const sub_and_nonsub_data = this.allData.non_subscriptions_today_total_all + this.allData.subscriptions_today_total_all;
        this.nonsubscriptions_percent = this.allData.non_subscriptions_today_total_all / sub_and_nonsub_data * 100;
        this.subscriptions_percent2 = this.allData.subscriptions_today_total_all / sub_and_nonsub_data * 100;

        const total_genders = this.allData.male + this.allData.female + this.allData.other;
        this.male_percent = this.allData.male / total_genders * 100;
        this.female_percent = this.allData.female / total_genders * 100;
        this.other_percent = this.allData.other / total_genders * 100;

        const total_platforms = this.allData.android + this.allData.ios + this.allData.web;
        this.android_percent = this.allData.android / total_platforms * 100;
        this.ios_percent = this.allData.ios / total_platforms * 100;
        this.web_percent = this.allData.web / total_platforms * 100;

        const total_packages = this.allData.subscriptions_today_total_yearly + this.allData.subscriptions_today_total;
        this.yearly_percent = this.allData.subscriptions_today_total_yearly / total_packages * 100;
        this.monthly_percent = this.allData.subscriptions_today_total / total_packages * 100;

        this.signup_count = this.allData?.users_today_total;
        this.subscriber_count_yearly = this.allData?.subscriptions_today_real_yearly;
        this.subscriber_count = this.allData?.subscriptions_today_total_today;
        this.cancel_count = this.allData?.cancelations_today_total_today;
        this.refund_count = this.allData?.refund_today

        // console.log(this.allData?.mostAddedInPlaylistLifetimeTop);
        // console.log(this.allData?.mostAddedToFavoritesLifetime?.top);
        this.playlist_tracks = this.allData?.mostAddedInPlaylistLifeTimeTop;
        this.popular_tracks = this.allData?.mostPopularLifetime;
        this.favorite_tracks = this.allData?.mostAddedToFavoritesLifetime?.top;

        // console.log(this.allData);
        // console.log("total : " , sub_data_total);
        // console.log("can : " , this.allData.cancelations_today_total);
        // console.log("sub : " , this.allData.subscriptions_today_total);
        // console.log('percent cancelations : ' + this.cancelations_percent)
        // console.log('percent subscription  : ' + this.subscriptions_percent)

      }
    },
    async setStore() {
      try {
        let result = await api.get("admin/verify");
        this.user_name = result.data.name;
        this.user_email = result.data.email;
        const access = result.data.is_super_admin;
        this.user_image = result.data.profile_image;
        this.user_type = result.data.type;
        this.user_status = result.data.status;
        this.utoken = localStorage.getItem("loginkey");

        this.$store.commit("getname", this.user_name);
        localStorage.setItem('user_name', this.user_name);

        this.$store.commit("getkey", this.utoken);
        this.$store.commit("getimage", this.user_image);

        localStorage.setItem('user_image', this.user_image);
        this.$store.commit("gettype", this.user_type);

        this.$store.commit("getemail", this.user_email);
        localStorage.setItem('user_email', this.user_email);

        this.$store.commit("access", access);
        localStorage.setItem('access', access);
        
        this.$store.commit("getstatus", this.user_status);
      } catch (error) {
        console.log(error);
      }
    },
  }
}
</script>

<style scoped>

.all-selects{
  height: calc(2rem + 2px) !important;
    background-color: #f4f4f4;
    font-size: 14px;
    outline: none;
}
.all-selects:focus{
    color: #495057;
    background-color: #fff;
    border-color: none;
    outline: none;
    box-shadow: none;
}
.card .card-body {
    padding: 2rem;
}


</style>